import React, { Component } from 'react';
import './App.css';
import Countdown from './Countdown.js';

class App extends Component {

  render() {
    const didYouKnow = [
      'Vedela si, že mravce sa navzájom zdravia úklonom?',
      'Vedela si, že kresliť som si začal len kvôli tomu, že som sa snažil zaujať Ťa?',
      'Vedela si, že v snahe získať tvoju pozornosť som zozbieral viac ako 100 zvieracích faktov?',
      'Vedela si, že tigre majú rovnaký vzor kože aj srsti?',
      'Vedela si, že pštros nevie cúvať?',
      'Vedela si, že dospelý nosorožec vyprodukuje až 20kg trusu za deň?',
      'Vedela si, že naozaj sa mi páči predstava že by som Ťa kusol do zadku :D (Sorry ak som to prehnal)',
      'Vedela si, že až kvôli tebe ma začala reálne trápiť moja gramatika?',
      'Vedela si, že muchy sú hluché?',
      'Vedela si, že losy cítia keď im mucha sadne na parohy?',
      'Vedela si, že dospelé havrany tvoria gangy?',
      'Vedela si, že telá tuleňov sú mimoriadne ohybné?',
      'Vedela si, že chobotnica má tri srdcia?',
      'Vedela si, že koza má zreničku v tvare obĺžnika?',
      'Vedela si, že keď som stopoval v Nórsku uvaril som polievku z vyhodenej brokolice a bola fakt dobrá?',
      'Vedela si, že písanie týchto faktov trvalo dlhšie ako programovanie zbytku webu?',
      'Vedela si, že tučniaky sú šteklivé a smejú sa?',
      'Vedela si, že švábom nechutia uhorky?',
    ];

    return (
      <div className="App">
        <header className="App-header">
          <h1>Ahoj {Math.random() >= 0.5 ? 'Timi' : 'Timča'}. Will you be my Valentine? :)</h1>
          <Countdown date={`2018-03-03T18:00:00`}/>
          <p className="App-text">
            Ďakujem za každú sekundu času ktorý si mi venovala. Chcem aby si vedela, že veľa pre mňa znamenáš a dúfam, že toto počítadlo sa dlho nezastaví ( romantické že? :D ).
            <br />
            <br />
            Viem že mi to neveríš a stále sa opakujem: v mojich očiach si úžasná, nádherná a cool. {Math.floor(Math.random() * didYouKnow.length) < 6  ? 'Si jednoducho sexy af.' : ''}
            <br />
            <br />
            P.S. {didYouKnow[Math.floor(Math.random() * didYouKnow.length)]}
          </p>
        </header>
      </div>
    );
  }
}

export default App;
